import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-navi';
import {Entry} from "@2023-sophians-web/components/src/types";
import {Photo} from "@2023-sophians-web/components/src/components";

interface EntryCardProps {
    entry: Entry;
    onlive: boolean;
}

export function EntryCard({ entry, onlive, className, href }: EntryCardProps & Link.Props) {
    return (
        <div className={classNames('entry-card', `is-entry-${entry.entry_id}`, className)}>
            <Link href={href}>
                <Photo
                    className={classNames({
                        'is-onlive': onlive
                    })}
                    src={`/img/entry/${entry.entry_id}/1_thumbnail.jpg`}
                    alt={entry.name ?? ""}
                >
                    <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                            <figcaption>
                                <p className="has-text-weight-bold">詳しく見る</p>
                            </figcaption>
                        </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                        {entry.entry_id === 1 && (
                            <p className="is-warning is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">グランプリ</p>
                        )}
                        {entry.entry_id === 2 && (
                            <p className="is-size-5 is-size-7-mobile has-text-futura-pt has-text-weight-bold has-text-italic tag">準グランプリ</p>
                        )}
                    </div>
                </Photo>
                <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-4-mobile is-size-4-tablet is-size-3-desktop is-margin-bottom-5px">{entry.name ?? ""}</p>
                    <p className="entry-card-title-university is-size-6-mobile is-size-5-tablet is-size-4-desktop is-margin-bottom-10px">{entry.faculty} {entry.grade}</p>
                    <p className="entry-card-title-sdgs is-size-7-mobile is-size-6-tablet is-size-5-desktop ">{entry.sdgs}</p>
                </div>
            </Link>
        </div>
    );
}
