import React from 'react';
import { LazyImageFull, ImageState } from 'react-lazy-images';
import TwoStairsColumns from "@2023-sophians-web/components/src/components/TwoStairsColumns";
import ConceptTextBox from "@2023-sophians-web/components/src/components/ConceptTextBox";
import classNames from "classnames";
import styled from 'styled-components';

const ConceptImageBox = styled.figure`
    > img {
    opacity: 0;
    transition: opacity 0.5s;
    &.is-load-success {
      opacity: 1;
    }
    }
`;

export function ConceptSection(props: React.AnchorHTMLAttributes<HTMLDivElement>) {
    return (
        <section {...props} className={classNames("section as-concept-section has-background-link has-angle-outside-bottom", props.className)}>
            <div className="container">
                <TwoStairsColumns className="columns is-gapless">
                    <div className="column">
                        <ConceptImageBox className="image is-1by1">
                            <LazyImageFull observerProps={{threshold: 0.01, rootMargin: "150px 0px"}} src="/img/2019@2x.jpg" alt="2019年の様子">
                                {({ imageProps, imageState, ref }) => {
                                    if (imageState === ImageState.NotAsked) {
                                        return <div ref={ref} />;
                                    }
                                    return (
                                        <img
                                            {...imageProps}
                                            className={classNames({
                                                'is-load-success': imageState === ImageState.LoadSuccess,
                                            })}
                                            ref={ref}
                                        />
                                    );
                                }}
                            </LazyImageFull>
                        </ConceptImageBox>
                    </div>
                    <div className="column">
                        <ConceptTextBox>
                            {props.children}
                        </ConceptTextBox>
                    </div>
                </TwoStairsColumns>
            </div>
        </section>
    );
}
