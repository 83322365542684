import React from 'react';
import { InstagramButton, TwitterButton } from '@2023-sophians-web/components/src/components';
import {Link} from "react-navi";

export function Hero() {
  return (
    <section className="hero is-large">
      <div className="hero-head">
        <nav className="navbar">
          <div className="container is-flex">
            <div className="navbar-item">
              <div className="field is-grouped is-grouped-multiline">
                <p className="control">
                  <Link href="/#about" className="button has-text-futura-pt is-text has-text-italic">
                    About
                  </Link>
                </p>
                <p className="control">
                  <Link href="/#news" className="button has-text-futura-pt is-text has-text-italic">
                    News
                  </Link>
                </p>
                <p className="control">
                  <Link href="https://sophians2022.mxcolle.com/" target="_blank" className="button has-text-futura-pt is-text has-text-italic">
                    2022
                  </Link>
                </p>
                <p className="control">
                  <TwitterButton href="https://twitter.com/sophianscontest" />
                </p>
                <p className="control">
                  <InstagramButton href="https://www.instagram.com/sophianscontest" />
                </p>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <figure className="image is-heroimage" style={{marginTop: 27}}>
        <picture>
          {/* 828px */}
          <source srcSet="img/hero@w828.webp" type="image/webp" media="(max-width: 414px)" />
          <source srcSet="img/hero@w828.jpg" media="(max-width: 414px)" />
          {/* 1536px */}
          <source srcSet="img/hero@w1536.webp" type="image/webp" media="(max-width: 768px)" />
          <source srcSet="img/hero@w1536.jpg" media="(max-width: 768px)" />
          {/* 2048px */}
          <source srcSet="img/hero@w2048.webp" type="image/webp" media="(max-width: 1024px)" />
          <source srcSet="img/hero@w2048.jpg" media="(max-width: 1024px)" />
          {/* 2432px */}
          <source srcSet="img/hero@w2432.webp" type="image/webp" media="(max-width: 1216px)" />
          <source srcSet="img/hero@w2432.jpg" media="(max-width: 1216px)" />
          {/* 2816px */}
          <source srcSet="img/hero@w2816.webp" type="image/webp" />
          <source srcSet="img/hero@w2816.jpg" />
          <img src={"/img/hero.jpg"} alt="Sophian's Contest 2023" />
        </picture>
      </figure>
    </section>
  );
}

export function HeroHeader() {
  return <section className="hero as-header">
    <div className="hero-head">
      <nav className="navbar">
        <div className="container is-flex">
          <div className="navbar-item">
            <div className="field is-grouped is-grouped-multiline">
              <p className="control">
                <Link href="/#about" className="button has-text-futura-pt is-text has-text-italic">
                  About
                </Link>
              </p>
              <p className="control">
                <Link href="/#news" className="button has-text-futura-pt is-text has-text-italic">
                  News
                </Link>
              </p>
              <p className="control">
                <Link href="https://sophians2022.mxcolle.com/" target="_blank" className="button has-text-futura-pt is-text has-text-italic">
                  2022
                </Link>
              </p>
              <p className="control">
                <TwitterButton href="https://twitter.com/sophianscontest" />
              </p>
              <p className="control">
                <InstagramButton href="https://www.instagram.com/sophianscontest2020" />
              </p>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div className="hero-body" style={{background: "none"}}>
      <div className="container has-text-centered">
        <Link href="/">
          <img src="/img/logo.svg" style={{ maxHeight: 200 }} alt="Sophian's Contest 2023" />
        </Link>
      </div>
    </div>
  </section>
}