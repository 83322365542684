import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2023-sophians-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
    for: list(entry),
    load: async () => {
        return [
            {
                id: 1,
                entry_id: 1,
                block: 'A',
                stage: '1',
                aspect: 'landscape',
                name: '大塚 健人',
                "faculty": "外国語学部\nドイツ語学科",
                sdgs: "SNSで持続可能な地方創生を！",
                goals:[10, 11],
                hashtags: ["#地方創生", "#SNS", "#起業"],
                "course": "",
                "grade": "4年",
                "birthday": "2001/8/20",
                "hometown": "大分県",
                "hobby": "趣味：旅行\n特技：動画制作",
                twitter: "sophians2023_no.1",
                instagram: "sophians2023_no.1",
                // コンテストに出場したきっかけ
                interview1: "現在、地方創生事業の会社の代表を務めています。その中で感じる、地域の課題・知られてない魅力をより多くの人に知ってもらうためです。\nまたソフィアンズコンテストをさらに盛り上げるために参加を決意しました。",
                // 頑張りたいこと
                interview2: "SNSを通じて、少しでも誰かのきっかけや行動する一助を担えるように頑張ります！",
                // コンテストを通じて伝えたいこと
                interview3: "地域の可能性・おもしろさ・魅力",
                // アピールポイント
                interview4: "行動力と発信力です。ラーメン屋運営しています。",
                // 所属サークル
                interview5: "今は引退しましたが、フットサルサークルとサッカーサークルにいました。",
                // アルバイト
                interview6: "スターバックス",
                // 自分の大学・学部自慢
                interview7: "違いを認め合えて、リスペクトできるところ",
                // マイブーム
                interview8: "銭湯巡り、最高です。",
                // 尊敬する人
                interview9: "家族・友達・会社の仲間",
                // 座右の銘
                interview10: "明日は明日の風が吹く",
                // コンテストへの意気込み
                interview11: "コンテストの活動を通して、みんなが地方に興味を持つきっかけ作りを行います！\nまた自分の発信を通じて、コンテストの魅力をより多くの人に伝えたいと思っています。\nファイナリスト5人・実行委員で精一杯頑張りますので応援よろしくお願いします！！！",
            },
            {
                id: 2,
                entry_id: 2,
                block: 'A',
                stage: '1',
                aspect: 'landscape',
                name: 'ブリンクマン\n恵美',
                "faculty": "国際教養学部\n国際教養学科",
                sdgs: "食の多様性と持続可能性",
                goals:[2, 3, 6, 10, 11, 13, 14, 15, 16],
                hashtags: ["#ヴィーガン", "#ハーフ", "#多様性"],
                "course": "",
                "grade": "3年",
                "birthday": "2002/11/13",
                "hometown": "千葉県船橋市",
                "hobby": "趣味は旅行とチアダンスで、特技は誰とでもすぐに仲良くなれることです！",
                twitter: "sophians2023_no.2",
                instagram: "sophians2023_no.2",
                // コンテストに出場したきっかけ
                interview1: "コンテストに参加することで、私の影響力を高めることができると思ったからです。また、多くの人々にヴィーガンに関心を持ってもらい、共感や理解を広げることができると思ったからです。",
                // 頑張りたいこと
                interview2: "ヴィーガンのイメージをポジティブに変えて、自分自身がより大きな影響力を持てるように頑張りたいです！",
                // コンテストを通じて伝えたいこと
                interview3: "ヴィーガンの理念や持続可能なライフスタイルについて伝えたいです！",
                // アピールポイント
                interview4: "明るくパワフルなところです！",
                // 所属サークル
                interview5: "上智大学ヴィーガンサークル",
                // アルバイト
                interview6: "現在なし",
                // 自分の大学・学部自慢
                interview7: "大学の食堂にヴィーガン・ハラルメニューがあることです。国際教養学部は、いつもから国際交流ができる点です。",
                // マイブーム
                interview8: "スーパー巡り",
                // 尊敬する人
                interview9: "母",
                // 座右の銘
                interview10: "You only live once",
                // コンテストへの意気込み
                interview11: "自分の個性を最大限に活かし、華やかなステージで魅力を存分に発揮します！このコンテストは私の夢への大一歩として、楽しみます！",
            },
            {
                id: 3,
                entry_id: 3,
                block: 'A',
                stage: '1',
                aspect: 'portrait',
                name: '原 麻里夢',
                "faculty": "神学部\n神学科",
                sdgs: "日本の中高生の自己肯定感を高める\n教育の在り方を創りたい",
                goals:[1, 3, 4, 8, 10, 16, 17],
                hashtags: ["#教育", "#神学", "#哲学", "#アートが好き", "#新しいものを創りたい", "#明るく笑顔で楽しもう", "#人好き", "#ダンス", "#お喋り", "#好奇心旺盛"],
                "course": "",
                "grade": "4年",
                "birthday": "1999/6/11",
                "hometown": "北海道・東京都",
                "hobby": "趣味: お料理・美味しいものを食べること・素敵な空間で友人と語り合うこと\n特技: 歌うこと・ダンス・韓国語・人の良いところをすぐに見つけられるところ",
                twitter: "sophians2023_no.3",
                instagram: "sophians2023_no.3",
                // コンテストに出場したきっかけ
                interview1: "「新たな教育の在り方を創りたい」\nコンテストを通してそのきっかけ作りをしたく出場を決めました。中学生時代、勉強ができない自分が学校社会で生きにくかったことを今でも覚えています。学校の学びもとても重要ですが、それをもって人生100年で何がしていきたいのか。夢や目的があったらワクワク生きられることが多いです^^ そのようなビジョンを一緒に見つけられる中高生のキャリアアドバイザー・学びのコーディネーターになることが私の夢です。",
                // 頑張りたいこと
                interview2: "社会課題を通して自分の想いや考えていることを、皆さんとフラットな形でシェアしたいです。そのツールとしてSNSを活用したいと思います。\nまた私自身が誰かの心の支えになれたり、何か良い影響をもたらせられたら嬉しいです。そのために発信者としても学び続け、成長し続けていきたいです^^",
                // コンテストを通じて伝えたいこと
                interview3: "「この豊かな国の、心の貧困を見た」というマザーテレサが日本に対しておっしゃった言葉に衝撃を受けたことがありました。実際に日本の若者の自殺率は世界的に見ても高い現状があります。神学部で学ぶ者として、人の内面の豊かさに焦点を当てながら、幸福感や自己肯定感に繋がるエッセンスを伝えたいです。愛とか幸せって、探してみると意外と身近にあったりするものだと思うんです。そしてそれらが感じられないという人が居たら、私がその人のそれらになりたい。🌐💎💙",
                // アピールポイント
                interview4: "様々な人と打ち解けられるところと行動力です。\n知的好奇心が旺盛な私は､普段自分の知らない世界や領域で過ごしている方と話すことが大好きです。オープンマインドな性格が相まって､とても盛り上がります^^\nまた行動力という点で、国内と海外(カンボジア・フィリピン)でのボランティアや、トビタテ留学JAPANを通しての韓国留学(10ヶ月)、コロナ禍でのオンライン個人塾の運営など多岐にわたって活動していました。",
                // 所属サークル
                interview5: "国内ボランティアサークルのSVN(Sophia Volunteer Network)",
                // アルバイト
                interview6: "AO受験予備校・キッザニア東京・博多もつ鍋屋さん・カフェ",
                // 自分の大学・学部自慢
                interview7: "教授の方々と友人たちが、家族のようにあたたかいところです。\n多様性の文化、人との違いも個性として受容して褒め合える文化が大好きです。\n総合大学であるため、色々な学びを横断し知見を得て深めていけるところは、私のような欲張りさんにぴったりです！！笑\nまた、友人が四ツ谷キャンパスに訪れるときには「海外に来たみたい！」とよく言われます。",
                // マイブーム
                interview8: "カフェ巡り・映画鑑賞・カラオケ・読書",
                // 尊敬する人
                interview9: "・家族、友人たち＿いつも味方でいてくれてありがとう！！感謝しています。大好きです。\n・学部のゼミの担当教授である原敬子先生\n・津田塾大学の創設者である津田梅子\n・歌手のmiwaさん\n→女性として力強く、美しくしなやかに生きていらっしゃる姿に尊敬の念を抱いています。",
                // 座右の銘
                interview10: "・人が変化することが、1番大きな奇跡だ。\n→変わりたいと願っても難しいことは多くあります。けれど変化した後の未来は明るく､奇跡のような日々が待っていると信じたいです。\n\n・行く言葉が美しければ、来る言葉も美しい (=가는 말이 고와야 오는 말이 곱다)。\n→発した言葉の通り自分に戻ってくる。もの・ひと・こと全てに肯定的な自分で在りたい。",
                // コンテストへの意気込み
                interview11: "このコンテストの期間は特に、毎日が自分との小さな勝負である、と捉えています。自分の言動・行動1つにも細やかな意識と配慮と責任を持つことを心がけます。皆様と共に社会課題解決やSDGs目標達成について理解を深め、個人・他者・組織・社会、そして大きな言葉になってしまいますが、日本や世界について考え、ひとつになれる方法を見出したいです。\n他者を笑顔にしようとする前に、まずは自分自身が楽しく笑顔で過ごしていきます。第4回ソフィアンズ コンテスト、応援よろしくお願いいたします。",
            },
            {
                id: 4,
                entry_id: 4,
                block: 'A',
                stage: '1',
                aspect: 'portrait',
                name: '張 智輝',
                "faculty": "理工学部\n情報理工学科",
                sdgs: "プログラミングをもっと身近に！",
                goals:[4, 5, 8],
                hashtags: ["#自由", "#学び", "#挑戦", "#プログラミング", "#Unbiased", "#マグロの末裔", "#キーボードの「tab」", "#ハードルは迂回する"],
                "course": "",
                "grade": "2年",
                "birthday": "2003/4/14",
                "hometown": "東京都",
                "hobby": "趣味 :様々なことに挑戦すること🔥\n特技 :目分量で水が何mLか当てること🥛",
                twitter: "sophians2023_no.4",
                instagram: "sophians2023_no.4",
                // コンテストに出場したきっかけ
                interview1: "僕が行っている活動を発信し、さらに規模を拡大できるチャンスだと考えたから。また、コンテストを通してより成長できると思ったから。",
                // 頑張りたいこと
                interview2: "僕の考えや情報を発信するだけでなく、具体的な活動を通して皆さんに体験していただける場を作っていきたいです。",
                // コンテストを通じて伝えたいこと
                interview3: "「プログラミングは難しい」「理系・男性のやるもの」「自分には関係ない」といったようなプログラミングに対する誤ったイメージをなくしたい、そして、プログラミングが多くの人にとって武器になるということを、知ってもらいたいです。",
                // アピールポイント
                interview4: "ガッツと体力です！！！💪\nコンテスト期間中、様々なことに継続的に挑戦していきたいと思います！！！🔥",
                // 所属サークル
                interview5: "Unbiased Programming\nSophia Game Lab",
                // アルバイト
                interview6: "IT系のお仕事を個人でもらっています💻",
                // 自分の大学・学部自慢
                interview7: "四ツ谷キャンパスに全学部が集まっていて、学部で隔たれない講義が多いので、様々な考え方や価値観を持つ人と触れることができる機会が多いです。日本で一番色鮮やかな大学だと思っています🌈",
                // マイブーム
                interview8: "卵を片手で割ろうとすること🥚\n（できたことがありません、誰か教えてください）\n１人でいるときに指パッチンすること☝🏻 ̖́-\n（これもできません、誰か教えてください）",
                // 尊敬する人
                interview9: "一緒に活動している先輩方\n常に目標をもって前に進み続ける姿勢に憧れます✨",
                // 座右の銘
                interview10: "千里の道も一歩から🐾\nそれが例え誰にでも簡単にできることであったとしても、その一歩を刻んでいくことでいつか自分にしか行けない場所に行ける、そう思っています。",
                // コンテストへの意気込み
                interview11: "目指せグランプリ🏆✨\nファイナリストおよび実行委員会のみなさまと協力して素晴らしいコンテストを作り上げていきます！応援よろしくお願いします🤲🏻",
            },
            {
                id: 5,
                entry_id: 5,
                block: 'A',
                stage: '1',
                aspect: 'portrait',
                name: '渡邊 春菜',
                "faculty": "総合人間科学部\n社会福祉学科",
                sdgs: "誰もが自身に合った居場所を\n獲得できる社会を創る！",
                goals:[1, 3, 4, 5, 8, 10, 11, 17],
                hashtags: ["＃社会福祉","＃コミュニティ", "＃生きづらさ", "＃自己理解"],
                "course": "",
                "grade": "1年",
                "birthday": "2004/4/8",
                "hometown": "神奈川県",
                "hobby": "趣味：写真を撮ること、散歩、スクラップブッキング\n特技：場づくり、思いを全力で伝えること",
                twitter: "sophians2023_no.5",
                instagram: "sophians2023_no.5",
                // コンテストに出場したきっかけ
                interview1: "私は高校生の頃にこのコンテストを知り、自身の問題意識に沿った社会発信を評価するという点に興味を持ちました。そして、中高生の生きづらさの解消のために自ら行動を起こしたいと思い、応募いたしました。このコンテストを通じて、「他者のために、他者とともに」行う活動の魅力を発信し、若者をはじめとする多様な人々が、他者との協働の中で自らの幸せを実現していける社会の実現に向け、一歩前進したいです。",
                // 頑張りたいこと
                interview2: "若者を取り巻く社会問題やその解消に向けた取り組みについての自分自身の理解や思考を深めつつ、それを自身の中にとどめず発信していきたいです。また、中高生の生きづらさの解消のために、多様な人とかかわれる機会の提供のために自ら活動を行っていきたいと思います。",
                // コンテストを通じて伝えたいこと
                interview3: "中高生の生きづらさの解消のために若者が抱える困難や人とのかかわりの価値を広めたいです。私は生きづらさの解消には多様な人とのかかわりが有効であると考え、これまでにイベントの運営や同好会の立ち上げ、居場所づくりにかかわる団体へのインタビューを行ってきました。このコンテストを通じて、課外活動や居場所といった他者とのかかわりが得られる活動への参加が中高生にとって身近になるよう、発信を行っていきたいです。",
                // アピールポイント
                interview4: "なんでも挑戦する行動力と思いを全力で伝える表現力です！これまで、学校内の活動や習い事に加えて同好会の立ち上げやイベントの運営、生きづらさ解消に向けた20回以上のインタビューなど興味を持ったことは何でも取り組んできました。また、感情表現が豊かで、劇表現活動やダンス、音楽を通じた表現活動を継続して行ってきたこともあり、思いを伝える力には自信があります。",
                // 所属サークル
                interview5: "ゴスペルサークル、手話サークル",
                // アルバイト
                interview6: "塾講師、ファストフード店",
                // 自分の大学・学部自慢
                interview7: "上智大学の魅力は、自らの学びを他者とともに深め、他者のために還元していける環境が整っていることだと思います。何かに興味関心を持って活動している人、努力している人が多く、日々いい刺激を受けてわくわくしながら自らの学びを深めています！",
                // マイブーム
                interview8: "自然を感じたり、好きな香りのものを身に着けるなど、日々の生活を幸せなものにするために少し工夫をすること。",
                // 尊敬する人
                interview9: "両親、信念をもって活動を頑張っている友人やコミュニティづくりにかかわる方々。",
                // 座右の銘
                interview10: "「生きる意味は幸せになることだ」\n私にとっての一番の幸せは自分の行動で他者を幸せにすることで、自分にとって真の意味でよい行動ができているかを意識するようにしています。",
                // コンテストへの意気込み
                interview11: "当事者や現場の声を聴き、多様化する生きづらさと人とのかかわりの魅力について発信することで、様々な人に自身と周りの人の幸せについて考えるきっかけを届けたいと考えています。さらに、自らの行動力と表現力を生かして活動や発信を行う中で、自分自身やコンテスト自体の前進にもつなげていけるよう頑張ります。応援よろしくお願いします！",
            },
        ];
    },
});
